import { Auth } from "aws-amplify";
import axios from "axios";
import { useEffect, useState } from "react";

import { Button, Table } from "..";
import { StoreModal } from "./StoreModal";

const serverUrl = process?.env?.REACT_APP_SERVER_URL;

export const Store = () => {
  const [trigger, setTrigger] = useState(true);
  const [items, setItems] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [storeModalIsOpen, setStoreModalIsOpen] = useState(false);

  const handleOpenStoreModal = () => setStoreModalIsOpen(true);
  const handleCloseStoreModal = () => setStoreModalIsOpen(false);

  const getToken = async () => {
    const data = await Auth.currentSession();

    return data.idToken.jwtToken;
  };

  useEffect(() => {
    getToken().then(function (token) {
      token = "Bearer " + token;
      let serverUrl = process?.env?.REACT_APP_SERVER_URL;
      axios
        .get(serverUrl + "store/skus", {
          headers: {
            "X-Hemingway-Authorization": token,
          },
        })
        .then((skus) => {
          setItems(
            skus.data.sort((a, b) => {
              const localeCompareResult = a.name
                .trim()
                .toLowerCase()
                .localeCompare(b.name.trim().toLowerCase());

              if (localeCompareResult === 0) {
                return Number(a.price) - Number(b.price);
              } else {
                return localeCompareResult;
              }
            })
          );
        });
    });
  }, [trigger]);

  const handleCreateItem = async (newItem) => {
    setLoading(true);
    getToken().then(function (token) {
      token = "Bearer " + token;

      axios
        .post(
          serverUrl + "store/sku/create",
          { ...newItem, sku: crypto.randomUUID() },
          {
            headers: {
              "X-Hemingway-Authorization": token,
            },
          }
        )
        .then((resp) => {
          console.log(resp)
          setLoading(false);
          setTrigger(!trigger);
          handleCloseStoreModal();
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response.data);
          setError(err.message + '. Details: ' +err.response.data); 
          setLoading(false);
        });
    });
  };

  const handleDeleteItem = async (itemToRemove) => {
    if (
      window.confirm(
        `Are you sure you want to delete the item '${itemToRemove.name}'`
      )
    ) {
      getToken().then(function (token) {
        token = "Bearer " + token;

        axios
          .delete(serverUrl + "store/sku/remove", {
            headers: {
              "X-Hemingway-Authorization": token,
            },
            data: { sku: itemToRemove.sku },
          })
          .then((resp) => {
            setTrigger(!trigger);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  const columnWidths = [];

  const headers = ["Name", "Description", "Price", ""];

  const rows = items.map((item) => [
    item.name,
    item.desc,
    item.price,
    <div
      style={{ cursor: "pointer" }}
      onClick={() => {
        handleDeleteItem(item);
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-trash"
        viewBox="0 0 16 16"
      >
        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
        <path
          fillRule="evenodd"
          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
        />
      </svg>
    </div>,
  ]);

  return (
    <>
      <div
        style={{
          marginBottom: "29px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ marginBottom: 0 }}>Store</h3>

        <StoreModal
          isOpen={storeModalIsOpen}
          closeModal={handleCloseStoreModal}
          handleCreateItem={handleCreateItem}
          loading={loading}
          error={error}
          openElement={
            <Button
              style={{ width: "120px", height: "35px", marginTop: 0 }}
              onClick={handleOpenStoreModal}
            >
              New Item
            </Button>
          }
        />
      </div>

      <Table columnWidths={columnWidths} headers={headers} rows={rows} />
    </>
  );
};
