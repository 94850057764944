import {Auth} from "aws-amplify";
import axios from "axios";
import Modal from "react-modal";
import {useEffect, useState} from "react";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
// import dayjs, {Dayjs} from 'dayjs';
// import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

import {
    handleCreateEvent, handleDeleteEvent,
    handleEditEvent,
} from "./handlers";
import {Button, Input} from "..";

import inputStyles from "../Input/styles.module.css";
import styles from "./styles.module.css";

let serverUrl = process.env.REACT_APP_SERVER_URL;

const customModalStyles = {
    content: {
        width: "500px",
        maxHeight: "100vh",
        margin: "0 auto",
        position: "unset",
        inset: "unset",
        background: "#252525",
    },
    overlay: {
        zIndex: 999999,
    },
};

export const Calendar = () => {
    const [state, setState] = useState([]);

    const getToken = async () => {
        const data = await Auth.currentSession();

        return data.idToken.jwtToken;
    };

    const getEvents = () => {
        const localEvents = [];
        getToken().then(function (token) {
            token = "Bearer " + token;
            axios
                .get(serverUrl + "events/getall", {
                    headers: {
                        "X-Hemingway-Authorization": token,
                        "API-Version": "2"
                    },
                })
                .then(function (response) {
                    // handle success
                    response.data.forEach((event) => {
                        localEvents.push({
                            id: event.event_id,
                            title: event.note,
                            start: new Date(event.start),
                            end: new Date(event.end),
                            seriesId: event.series_id,
                            details: event.event_details,
                            attendees: event.attendees,
                            phone: event.phone,
                            email: event.email,
                            registration_link: event.registration_link,
                            event_sponsor: event.event_sponsor,
                            allDay: false,
                        });
                    });
                    setState(localEvents);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(() => {
                });
        });
    }
    useEffect(() => {
        getEvents()
    }, []);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [eventToEdit, setEventToEdit] = useState(null);
    const [selectInfo, setSelectInfo] = useState();
    const [title, setTitle] = useState();
    const [details, setDetails] = useState();
    const [link, setLink] = useState();
    const [id, setId] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [startTime, setStartTime] = useState();
    const [oldTitle, setOldTitle] = useState();
    const [oldStart, setOldStart] = useState();
    const [oldEnd, setOldEnd] = useState();
    const [endTime, setEndTime] = useState();
    const [date, setDate] = useState();
    const [sendNotificationNow, setSendNotificationNow] = useState(false);
    const [days, setDays] = useState([]);
    const [repeat, setRepeat] = useState(false);
    const [start, setStart] = useState()
    const [end, setEnd] = useState()
    const [seriesId, setSeriesId] = useState()
    const [meta, setMeta] = useState()
    const [sponsor, setSponsor] = useState()
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()
    const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);

    const toggleCollapsible = () => {
        setIsCollapsibleOpen(!isCollapsibleOpen);
    };

    function openCreateModal(selectInfo) {
        setSelectInfo(selectInfo);
        setTitle("")
        setDetails("")
        setLink("")
        setSponsor("")
        setEmail("")
        setPhone("")
        setCreateModalIsOpen(true);
    }

    async function submitCreateModal() {
        await handleCreateEvent(
            selectInfo,
            title,
            repeat,
            fromDate,
            toDate,
            days,
            sendNotificationNow,
            details,
            link,
            sponsor,
            email,
            phone
        );
        await getEvents()

        setCreateModalIsOpen(false);
        setSendNotificationNow(false);
    }


    function closeCreateModal() {
        setCreateModalIsOpen(false);
        setRepeat(false)
        setSendNotificationNow(false)

    }

    async function submitEditModal() {
        await handleEditEvent(
            selectInfo,
            title,
            repeat,
            fromDate,
            toDate,
            days,
            startTime,
            endTime,
            oldStart,
            oldEnd,
            oldTitle,
            sendNotificationNow,
            date,
            id,
            seriesId,
            start, end, details, meta, link, sponsor, phone, email
        );
        await getEvents()
        setEditModalIsOpen(false);
        setSendNotificationNow(false)
    }

    const extractTime = (dateString) => {
        const date = new Date(dateString);
        let hours = date.getHours()
        let minutes = date.getMinutes()

        // Pad with leading zeros
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;

        return hours + ':' + minutes;
    }

    function closeEditModal() {
        setEditModalIsOpen(false);
        setRepeat(false)
        setSendNotificationNow(false)
    }

    function extractDate(inputDate) {
        // Parse the date
        const date = new Date(inputDate);

        // Format the date to "MM/DD/YYYY"
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, hence +1
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    }

    Modal.setAppElement("#root");

    useEffect(() => {
        setTitle(eventToEdit?.title)
        setOldTitle(eventToEdit?.title)
        setStartTime(extractTime(eventToEdit?.start))
        setEndTime(extractTime(eventToEdit?.end))
        setOldStart(extractTime(eventToEdit?.start))
        setOldEnd(extractTime(eventToEdit?.end))
        setDate(extractDate(eventToEdit?.start))

        setStart(eventToEdit?.start)
        setEnd(eventToEdit?.end)
        setId(eventToEdit?.id)
        setSeriesId(eventToEdit?.extendedProps?.seriesId)
        setDetails(eventToEdit?.extendedProps?.details)
        setLink(eventToEdit?.extendedProps?.registration_link)
        setMeta(eventToEdit?.extendedProps)

        setSponsor(eventToEdit?.extendedProps?.event_sponsor)
        setEmail(eventToEdit?.extendedProps?.email)
        setPhone(eventToEdit?.extendedProps?.phone)
    }, [editModalIsOpen, eventToEdit])

    function daysOfTheWeek(day, add) {
        if (add) {
            setDays(prevDays => {
                if (!prevDays.includes(day)) {
                    return [...prevDays, day];
                }
                return prevDays;
            });
        } else {
            setDays(prevDays => prevDays.filter(value => value !== day));
        }
    }

    // Function to format date as "YYYY-MM-DD"
    const formatDate = (date) => {
        const d = new Date(date),
            year = d.getFullYear(),
            month = ('0' + (d.getMonth() + 1)).slice(-2), // Months are 0 based. Add leading 0 and slice last two digits
            day = ('0' + d.getDate()).slice(-2); // Add leading 0 and slice last two digits
        return [year, month, day].join('-');
    };
    useEffect(() => {
        if (seriesId) {
            const eventsWithMatchingSeriesId = state.filter(event => event.seriesId === seriesId);

            // If there are events in the series, determine the earliest and latest dates
            if (eventsWithMatchingSeriesId.length > 0) {
                // Sort events by start date
                eventsWithMatchingSeriesId.sort((a, b) => new Date(a.start) - new Date(b.start));

                // Set fromDate to the start date of the first event (earliest)
                const earliestEvent = eventsWithMatchingSeriesId[0];
                setFromDate(formatDate(earliestEvent.start)); // or new Date(earliestEvent.start) if it needs to be a Date object

                // Assuming the events are already sorted by start date, the latest event would be the last one in the array
                const latestEvent = eventsWithMatchingSeriesId[eventsWithMatchingSeriesId.length - 1];
                setToDate(formatDate(latestEvent.start)); // or new Date(latestEvent.start) if it needs to be a Date object

                // Reset days state
                setDays([]);

                // Extract days of the week from these events
                let eventDays = eventsWithMatchingSeriesId.map(event =>
                    (new Date(event.start).getDay() + 6) % 7 // Adjust indexing to match your system (if necessary)
                );

                // Deduplicate days
                eventDays = [...new Set(eventDays)];

                // For each day in this list, set the checkbox state for that day to true
                eventDays.forEach(day => {
                    daysOfTheWeek(day, true);
                });
            } else {
                // If there are no matching events, clear the fromDate, toDate, and days
                setFromDate(null);
                setToDate(null);
                setDays([]);
            }
        } else {
            setFromDate(null);
            setToDate(null);
            setDays([]);
        }
    }, [seriesId, state]);


    const submitDelete = async () => {
        await handleDeleteEvent(id, repeat, seriesId);
        await getEvents()
        setEditModalIsOpen(false);
    }

    const optional =
        <>
            <Button onClick={toggleCollapsible} className={styles.collapsibleButton}>
                {isCollapsibleOpen ? 'Hide Additional Options' : 'Show Additional Options'}
                {!isCollapsibleOpen ? ' ▼' : ' ▲'} {/* Unicode characters for the carets */}

            </Button>
            {isCollapsibleOpen && (
                <div className={styles.collapsibleSection}>
                    {/* Add your optional inputs here */}
                    <Input
                        label="Sponsor"
                        onChange={(event) => setSponsor(event.target.value)}
                        value={sponsor}
                        placeholder="Enter Sponsor"
                        className={inputStyles.optionalInput} // Assume you want to style your optional inputs differently
                    />
                    <Input
                        label="Contact Phone"
                        onChange={(event) => setPhone(event.target.value)}
                        value={phone}
                        placeholder="Enter Phone Number"
                        className={inputStyles.optionalInput}
                    />
                    <Input
                        label="Contact Email"
                        onChange={(event) => setEmail(event.target.value)}
                        value={email}
                        placeholder="Enter Email"
                        className={inputStyles.optionalInput}
                    />
                </div>
            )}
        </>

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>

            <div className={styles.calendarContainer}>

                {/*Edit Modal*/}
                <Modal
                    isOpen={editModalIsOpen}
                    onRequestClose={closeCreateModal}
                    style={customModalStyles}
                    contentLabel="Create Event"
                    shouldFocusAfterRender
                    ariaHideApp
                >
                    <h2>Edit Event</h2>

                    <Input
                        label="Title"
                        onChange={(event) => setTitle(event.target.value)}
                        value={title}
                        placeholder="Enter Title"
                    />

                    <Input
                        label="Details"
                        multiline={true}
                        onChange={(event) => setDetails(event.target.value)}
                        value={details}
                        placeholder="Enter Details"
                    />
                    <Input
                        label="Registration Link"
                        multiline={true}
                        onChange={(event) => setLink(event.target.value)}
                        value={link}
                        placeholder="Enter Registration Link"
                    />

                    {optional}
                    <Input
                        label="Start Time"
                        onChange={(event) => setStartTime(event.target.value)}
                        value={startTime}
                        type="time"
                    />
                    <Input
                        label="End Time"
                        onChange={(event) => setEndTime(event.target.value)}
                        value={endTime}
                        type="time"

                    />
                    {seriesId &&
                    <Input
                        label="Edit Series"
                        checked={repeat}
                        onChange={(event) => setRepeat(event.target.checked)}
                        type="checkbox"
                    />}

                    {repeat && (
                        <div className={styles.repeat}>
                            <Input
                                label="From"
                                onChange={(event) => setFromDate(event.target.value)}
                                type="date"
                                value={fromDate}

                            />

                            <label className={inputStyles.label}>Every</label>

                            <div className={styles.days}>
                                <Input
                                    inline
                                    label="Mon"
                                    onChange={(event) => daysOfTheWeek(0, event.target.checked)}
                                    checked={days.includes(0)}
                                    type="checkbox"
                                />

                                <Input
                                    inline
                                    label="Tue"
                                    onChange={(event) => daysOfTheWeek(1, event.target.checked)}
                                    checked={days.includes(1)}

                                    type="checkbox"
                                />

                                <Input
                                    inline
                                    label="Wed"
                                    onChange={(event) => daysOfTheWeek(2, event.target.checked)}
                                    checked={days.includes(2)}
                                    type="checkbox"
                                />

                                <Input
                                    inline
                                    label="Thu"
                                    checked={days.includes(3)}
                                    onChange={(event) => daysOfTheWeek(3, event.target.checked)}
                                    type="checkbox"
                                />

                                <Input
                                    inline
                                    label="Fri"
                                    checked={days.includes(4)}
                                    onChange={(event) => daysOfTheWeek(4, event.target.checked)}
                                    type="checkbox"
                                />

                                <Input
                                    inline
                                    checked={days.includes(5)}
                                    label="Sat"
                                    onChange={(event) => daysOfTheWeek(5, event.target.checked)}
                                    type="checkbox"
                                />

                                <Input
                                    inline
                                    label="Sun"
                                    checked={days.includes(6)}
                                    onChange={(event) => daysOfTheWeek(6, event.target.checked)}
                                    type="checkbox"
                                />

                                <Input
                                    label="Until"
                                    value={toDate}
                                    onChange={(event) => setToDate(event.target.value)}
                                    type="date"
                                />
                            </div>
                        </div>
                    )}

                    {/* <Input
                        label="Send Notification When Created"
                        onChange={(event) => setSendNotificationNow(event.target.checked)}
                        type="checkbox"
                    /> */}

                    <div className={styles.controlButtons}>
                        <Button style={{width: "150px"}} onClick={closeEditModal}>
                            Close
                        </Button>
                        <Button style={{width: "150px"}} onClick={submitDelete}>
                            {repeat ? "Delete All Events" : "Delete Event"}
                        </Button>
                        <Button style={{width: "150px"}} onClick={submitEditModal}>
                            {repeat ? "Edit All Events" : "Edit Event"}
                        </Button>
                    </div>
                </Modal>

                <Modal
                    isOpen={createModalIsOpen}
                    onRequestClose={closeCreateModal}
                    style={customModalStyles}
                    contentLabel="Create Event"
                    shouldFocusAfterRender
                    ariaHideApp
                >
                    <h2>Create Event</h2>

                    <Input
                        label="Title"
                        onChange={(event) => setTitle(event.target.value)}
                        placeholder="Enter Title"
                    />
                    <Input
                        label="Details"
                        onChange={(event) => setDetails(event.target.value)}
                        value={details}
                        placeholder="Enter Details"
                    />
                    <Input
                        label="Registration Link"
                        multiline={true}
                        onChange={(event) => setLink(event.target.value)}
                        value={link}
                        placeholder="Enter Registration Link"
                    />

                    {optional}
                    <Input
                        label="Repeat"
                        checked={repeat}
                        onChange={(event) => setRepeat(event.target.checked)}
                        type="checkbox"
                    />

                    {repeat && (
                        <div className={styles.repeat}>
                            <Input
                                label="From"
                                onChange={(event) => setFromDate(event.target.value)}
                                type="date"
                            />

                            <label className={inputStyles.label}>Every</label>

                            <div className={styles.days}>
                                <Input
                                    inline
                                    label="Mon"
                                    onChange={(event) => daysOfTheWeek(0, event.target.checked)}
                                    type="checkbox"
                                />

                                <Input
                                    inline
                                    label="Tue"
                                    onChange={(event) => daysOfTheWeek(1, event.target.checked)}
                                    type="checkbox"
                                />

                                <Input
                                    inline
                                    label="Wed"
                                    onChange={(event) => daysOfTheWeek(2, event.target.checked)}
                                    type="checkbox"
                                />

                                <Input
                                    inline
                                    label="Thu"
                                    onChange={(event) => daysOfTheWeek(3, event.target.checked)}
                                    type="checkbox"
                                />

                                <Input
                                    inline
                                    label="Fri"
                                    onChange={(event) => daysOfTheWeek(4, event.target.checked)}
                                    type="checkbox"
                                />

                                <Input
                                    inline
                                    label="Sat"
                                    onChange={(event) => daysOfTheWeek(5, event.target.checked)}
                                    type="checkbox"
                                />

                                <Input
                                    inline
                                    label="Sun"
                                    onChange={(event) => daysOfTheWeek(6, event.target.checked)}
                                    type="checkbox"
                                />

                                <Input
                                    label="Until"
                                    onChange={(event) => setToDate(event.target.value)}
                                    type="date"
                                />
                            </div>
                        </div>
                    )}

                    <Input
                        label="Send Notification When Created"
                        onChange={(event) => setSendNotificationNow(event.target.checked)}
                        type="checkbox"
                    />

                    <div className={styles.controlButtons}>
                        <Button style={{width: "150px"}} onClick={closeCreateModal}>
                            Close
                        </Button>

                        <Button style={{width: "150px"}} onClick={submitCreateModal}>
                            Create Event
                        </Button>
                    </div>
                </Modal>


                <FullCalendar
                    height={900}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: "title",
                        center: "",
                        right: "prev today next",
                    }}
                    initialView="timeGridWeek"
                    editable={false}
                    selectable
                    weekends
                    expandRows
                    buttonText={{
                        today: "Today",
                    }}
                    titleRangeSeparator=" - "
                    dayHeaderFormat={{
                        weekday: "short",
                        month: "numeric",
                        day: "2-digit",
                        omitCommas: true,
                    }}
                    allDayContent={
                        <p style={{margin: "16px 19px 16px -19px"}}>All Day</p>
                    }
                    slotLabelContent={({date}) => (
                        <p style={{position: "relative", top: "12px", margin: "0 19px"}}>
                            {date
                                .toLocaleString("en-US", {hour: "numeric", hour12: true})
                                .toLowerCase()}
                        </p>
                    )}
                    events={state}
                    eventBackgroundColor="#E8D17E"
                    eventTextColor="#000"
                    eventBorderColor="transparent"
                    eventMinHeight={24}
                    timeZone={'local'}
                    eventContent={({event}) => {
                        if (!event?.end) return
                        let endTime = event.end
                            ?.toLocaleString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                            })
                            .toLowerCase();

                        let [endTimeWithoutAmPmString, endTimeAmPmString] =
                            endTime?.split(" ");

                        if (endTimeWithoutAmPmString?.endsWith(":00")) {
                            endTimeWithoutAmPmString = endTimeWithoutAmPmString?.split(":")[0];
                        }

                        endTime = `${endTimeWithoutAmPmString}${endTimeAmPmString}`;

                        let startTime = event.start
                            .toLocaleString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                            })
                            .toLowerCase();

                        let [startTimeWithoutAmPmString, startTimeAmPmString] =
                            startTime.split(" ");

                        if (startTimeWithoutAmPmString.endsWith(":00")) {
                            startTimeWithoutAmPmString =
                                startTimeWithoutAmPmString.split(":")[0];
                        }

                        startTime = `${startTimeWithoutAmPmString}${
                            startTimeAmPmString !== endTimeAmPmString ? startTimeAmPmString : ""
                        }`;

                        const eventDurationInSeconds = event.end - event.start;

                        return eventDurationInSeconds <= 1800000 ? (
                            <div
                                style={{
                                    padding: "4px",
                                    fontSize: "12px",
                                    lineHeight: "12px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                                title={`${startTime} - ${endTime} - ${event.title}`}
                            >
              <span>
                {startTime} - {endTime}
              </span>{" "}
                                - <span style={{fontWeight: "600"}}>{event.title}</span>
                            </div>
                        ) : (
                            <div
                                style={{padding: "6px", fontSize: "12px"}}
                                title={`${event.title} - ${startTime} - ${endTime}`}
                            >
                                <p
                                    style={{
                                        margin: 0,
                                        fontWeight: "600",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitLineClamp:
                                            Math.round(eventDurationInSeconds / 1800000) - 1,
                                        WebkitBoxOrient: "vertical",
                                    }}
                                >
                                    {event.title}
                                </p>
                                <p
                                    style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {startTime} - {endTime}
                                </p>
                            </div>
                        );
                    }}
                    select={openCreateModal}
                    eventClick={(e) => {
                        setEditModalIsOpen(true)
                        setEventToEdit(e.event)
                    }}
                    eventAdd={(e) => {
                    }}
                    eventChange={(e) => {
                    }}
                    eventRemove={(e) => {
                    }}
                />

            </div>
        </LocalizationProvider>

    );
};
