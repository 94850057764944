import { Auth } from "aws-amplify";
import axios from "axios";
import { useEffect, useState } from "react";

import { Table } from "..";

export const Reports = () => {
  const [reports, setReports] = useState([]);

  const getToken = async () => {
    const data = await Auth.currentSession();

    return data.idToken.jwtToken;
  };

  useEffect(() => {
    getToken().then(function (token) {
      token = "Bearer " + token;
      let serverUrl = process?.env?.REACT_APP_SERVER_URL;
      axios
        .get(serverUrl + "reports/getall", {
          headers: {
            "X-Hemingway-Authorization": token,
          },
        })
        .then((reports) => {
          setReports(
            reports.data.sort((a, b) =>
              a.reported
                .trim()
                .toLowerCase()
                .localeCompare(b.reported.trim().toLowerCase())
            )
          );
        });
    });
  }, []);

  const columnWidths = ["73%"];

  const headers = ["Reported User", "Reported By"];

  const rows = reports.map((report) => [report.reported, report.reportee]);

  return (
    <>
      <h3 style={{ marginBottom: "29px" }}>Reports</h3>

      <Table columnWidths={columnWidths} headers={headers} rows={rows} />
    </>
  );
};
