// handler functions that all calendar components will use

import {Auth} from "aws-amplify";
import axios from "axios";

let serverUrl = process.env.REACT_APP_SERVER_URL;

function toISOStringWithoutMillis(date, shift = -10) {
    let newDate = new Date(date.getTime());

    newDate.setUTCHours(newDate.getUTCHours() + 0);
    return newDate.getTime();
}

const getToken = async () => {
    const data = await Auth.currentSession();

    return data.idToken.jwtToken;
};
const getUsername = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const username = user.username; // This is the Cognito username

        return username;
    } catch (error) {
        console.error('Error getting the username:', error);
        return null; // Handle the error as needed
    }
};
// this function handles the creation of an event
export const handleCreateEvent = async (
    selectInfo,
    title,
    repeat,
    fromDate,
    toDate,
    days,
    sendNotificationNow,
    details,
    link,
    sponsor,
    email,
    phone
) => {
    let token = await getToken();
    token = "Bearer " + token;

    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection
    const username = await getUsername()

    if (title) {

        const startDateTime = toISOStringWithoutMillis(new Date(selectInfo.startStr), 0);
        const endDateTime = toISOStringWithoutMillis(new Date(selectInfo.endStr), 0);


        const eventData = {
            start: startDateTime,
            end: endDateTime,
            note: title,
            username: username,
            sendNotificationNow: sendNotificationNow,
            event_details: details,
            registration_link: link,
            event_sponsor: sponsor,
            email: email,
            phone: phone,
            daySelected: selectInfo.start,

        };

        if (repeat) {
            Object.assign(eventData, {
                fromDate: fromDate,
                toDate: toDate,
                days: days,

            });

            await axios.post(
                serverUrl + "events/addMany",
                eventData,
                {
                    headers: {
                        "X-Hemingway-Authorization": token,
                    },
                }
            );
        } else {
            await axios.post(
                serverUrl + "events/add",
                eventData,
                {
                    headers: {
                        "X-Hemingway-Authorization": token,
                    },
                }
            );
        }
    }
};

const convertTo12HourFormat = (time) => {
    const [hour, minute] = time.split(':').map(Number);
    const period = hour >= 12 ? 'pm' : 'am';
    const convertedHour = hour > 12 ? hour - 12 : (hour === 0 ? 12 : hour);
    return `${convertedHour}:${String(minute).padStart(2, '0')} ${period}`;
}
export const handleDeleteEvent = async (eventId, repeat, seriesId) => {
    let token = await getToken();
    token = "Bearer " + token;

    if (eventId) {
        await axios.post(
            serverUrl + "events/remove",
            {
                event_id: eventId
            },
            {
                headers: {
                    "X-Hemingway-Authorization": token,
                },
            }
        );
    }

    // If you also want to support bulk deletion based on repeating events,
    // ensure your backend can handle this via the event ID as well.
    if (repeat) {
        await axios.post(
            serverUrl + "events/removeMany",
            {
                series_id: seriesId
            },
            {
                headers: {
                    "X-Hemingway-Authorization": token,
                },
            }
        );
    }
};

// this function handles the deletion of an event
export const handleEditEvent = async (
    selectInfo,
    title,
    repeat,
    fromDate,
    toDate,
    days,
    startTime,
    endTime,
    oldStart,
    oldEnd,
    oldTitle,
    sendNotificationNow,
    date,
    event_id,
    seriesId, start, end, details, meta, link,
    sponsor, phone, email
) => {
    let token = await getToken();
    token = "Bearer " + token;

    const username = await getUsername()
    if (repeat) {
        // Delete the series with the given seriesId
        await axios.post(
            serverUrl + "events/removeMany",
            {
                series_id: seriesId
            },
            {
                headers: {
                    "X-Hemingway-Authorization": token,
                },
            }
        );

        // Create a new series with the updated event data
        const localStart = new Date(start.toISOString().split('T')[0] + "T" + startTime);
        const localEnd = new Date(start.toISOString().split('T')[0] + "T" + endTime);

        const startDateTime = toISOStringWithoutMillis(new Date(localStart));
        const endDateTime = toISOStringWithoutMillis(new Date(localEnd));

        const eventData = {
            start: startDateTime,
            end: endDateTime,
            note: title,
            fromDate: fromDate,
            toDate: toDate,
            days: days,
            event_details: details,
            sendNotificationNow: sendNotificationNow,
            registration_link: link,
            username: username,
            daySelected: selectInfo.start,

            attendees: meta.attendees,
            phone: phone ? phone : meta.phone,
            email: email ? email : meta.email,
            event_sponsor: sponsor ? meta.event_sponsor : meta.event_sponsor,

        };

        await axios.post(
            serverUrl + "events/addMany",
            eventData,
            {
                headers: {
                    "X-Hemingway-Authorization": token,
                },
            }
        );
    } else {
        // If not a repeating event, just update the single event
        const localStart = new Date(start.toISOString().split('T')[0] + "T" + startTime); // Append 'Z' to indicate UTC time
        const localEnd = new Date(start.toISOString().split('T')[0] + "T" + endTime); // Append 'Z' to indicate UTC time
        // Add the time zone offset in minutes

        const startDateTime = toISOStringWithoutMillis(new Date(localStart));
        const endDateTime = toISOStringWithoutMillis(new Date(localEnd));

        await axios.post(
            serverUrl + "events/update",
            {
                event_id,
                start: startDateTime,
                end: endDateTime,
                note: title,
                event_details: details,
                series_id: '',
                registration_link: link,
                username: username,

                attendees: meta.attendees,
                phone: phone ? phone : meta.phone,
                email: email ? email : meta.email,
                event_sponsor: sponsor ? meta.event_sponsor : meta.event_sponsor,
            },
            {
                headers: {
                    "X-Hemingway-Authorization": token,
                },
            }
        );
    }
};
