import { Auth } from "aws-amplify";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";

import { Button, FounderIcon, Input, Table } from "..";

import styles from "./styles.module.css";

const serverUrl = process?.env?.REACT_APP_SERVER_URL;

export const Clubs = (props) => {
  const { selectedClub, setSelectedClub } = props;

  const [trigger, setTrigger] = useState(true);
  const [clubs, setClubs] = useState([]);

  const handleClubClick = (index) => {
    setSelectedClub(clubs[index]);
  };

  const [users, setUsers] = useState([]);

  const [newClubModalIsOpen, setNewClubModalIsOpen] = useState(false);

  const handleOpenNewClubModal = () => setNewClubModalIsOpen(true);
  const handleCloseNewClubModal = () => setNewClubModalIsOpen(false);

  const [newClub, setNewClub] = useState({
    name: "",
    description: "",
    manager: "",
    meetingTime: "",
    icon: "",
  });

  const handleNewClubInputChange = (e, key) => {
    setNewClub((prevState) => {
      return {
        ...prevState,
        [key]: e.target.value,
      };
    });
  };

  const [addMemberModalIsOpen, setAddMemberModalIsOpen] = useState(false);

  const handleOpenAddMemberModal = () => setAddMemberModalIsOpen(true);
  const handleCloseAddMemberModal = () => setAddMemberModalIsOpen(false);

  const [newMember, setNewMember] = useState({ cognito_username: "" });

  const handleNewMemberInputChange = (e, key) => {
    setNewMember((prevState) => {
      return {
        ...prevState,
        [key]: e.target.value,
      };
    });
  };

  const getToken = async () => {
    const data = await Auth.currentSession();

    return data.idToken.jwtToken;
  };

  useEffect(() => {
    getToken().then(function (token) {
      token = "Bearer " + token;

      axios
        .get(serverUrl + "users", {
          headers: {
            "X-Hemingway-Authorization": token,
          },
        })
        .then((users) => {
          setUsers(
            users.data.sort((a, b) =>
              a.name
                ?.trim()
                .toLowerCase()
                .localeCompare(b.name?.trim().toLowerCase())
            )
          );
        });
    });
  }, []);

  useEffect(() => {
    getToken().then(function (token) {
      token = "Bearer " + token;

      axios
        .get(serverUrl + "clubs", {
          headers: {
            "X-Hemingway-Authorization": token,
          },
        })
        .then((clubs) => {
          setClubs(
            clubs.data.sort((a, b) =>
              a.name
                ?.trim()
                .toLowerCase()
                .localeCompare(b.name?.trim().toLowerCase())
            )
          );
        });
    });
  }, [trigger]);

  const handleCreateClub = async () => {
    getToken().then(function (token) {
      token = "Bearer " + token;

      axios
        .post(serverUrl + "clubs/add", newClub, {
          headers: {
            "X-Hemingway-Authorization": token,
          },
        })
        .then((resp) => {
          setTrigger(!trigger);
          handleCloseNewClubModal();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleDeleteClub = async (clubToRemove) => {
    getToken().then(function (token) {
      token = "Bearer " + token;

      axios
        .delete(serverUrl + "clubs/remove", {
          headers: {
            "X-Hemingway-Authorization": token,
          },
          data: { name: clubToRemove.name },
        })
        .then((resp) => {
          setTrigger(!trigger);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleAddMember = async () => {
    getToken().then(function (token) {
      token = "Bearer " + token;

      axios
        .put(
          serverUrl + "clubs/addMember",
          {
            club_name: selectedClub.name,
            user_id: newMember.cognito_username,
          },
          {
            headers: {
              "X-Hemingway-Authorization": token,
            },
          }
        )
        .then((resp) => {
          setTrigger(!trigger);
          handleCloseAddMemberModal();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleRemoveMember = async (memberToRemove) => {
    getToken().then(function (token) {
      token = "Bearer " + token;

      axios
        .delete(serverUrl + "clubs/removeMember", {
          headers: {
            "X-Hemingway-Authorization": token,
          },
          data: {
            club_name: selectedClub.name,
            user_id: memberToRemove.user_id || memberToRemove.cognito_username,
          },
        })
        .then((resp) => {
          setTrigger(!trigger);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const columnWidths =
    selectedClub === undefined
      ? ["73%"]
      : ["45px", undefined, undefined, undefined, "23%", ""];

  const headers =
    selectedClub === undefined
      ? ["Name", "Members", ""]
      : ["", "Name", "Email", "Member Since", "Last Check In", ""];

  const rows =
    selectedClub === undefined
      ? clubs.map((club, index) => [
          <span
            className={styles.clubName}
            onClick={() => handleClubClick(index)}
          >
            {club.name}
          </span>,
          club.members.length,
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleDeleteClub(club);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-trash"
              viewBox="0 0 16 16"
            >
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
              <path
                fillRule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              />
            </svg>
          </div>,
        ])
      : clubs
          .filter((club) => club.name === selectedClub.name)[0]
          ?.members.map((member) => {
            const user = users.filter(
              (user) => user.cognito_username === member.user_id
            )[0];

            if (!user) {
              return [
                undefined,
                `Invalid User ID: ${member.user_id}`,
                undefined,
                undefined,
                undefined,
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleRemoveMember(member);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fillRule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </div>,
              ];
            }

            return [
              user.status_icon === 1 && (
                <div style={{ marginTop: "2px" }}>
                  <FounderIcon />
                </div>
              ),
              user.name,
              user.email,
              user.member_since,
              user.checked_in
                ? new Date(user.checked_in).toLocaleString()
                : "Never",
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleRemoveMember(user);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </div>,
            ];
          })
          .sort((a, b) =>
            a[1]?.trim().toLowerCase().localeCompare(b[1]?.trim().toLowerCase())
          ) || [];

  return selectedClub === undefined ? (
    <>
      <div
        style={{
          marginBottom: "29px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ marginBottom: 0 }}>Clubs</h3>

        <Button
          style={{ width: "120px", height: "35px", marginTop: 0 }}
          onClick={handleOpenNewClubModal}
        >
          New Club
        </Button>
      </div>

      <Table columnWidths={columnWidths} headers={headers} rows={rows} />

      <Modal
        show={newClubModalIsOpen}
        onHide={handleCloseNewClubModal}
        dialogClassName={styles.modal}
      >
        <Modal.Header closeButton />

        <Modal.Body>
          <h4>New Club</h4>

          <Input
            label="Club Name"
            value={newClub.name}
            onChange={(e) => handleNewClubInputChange(e, "name")}
          />

          <Input
            label="Club Manager"
            value={newClub.manager}
            onChange={(e) => handleNewClubInputChange(e, "manager")}
          />

          <Input
            label="Club Description"
            value={newClub.description}
            onChange={(e) => handleNewClubInputChange(e, "description")}
          />

          <Input
            label="Meeting Time"
            value={newClub.meetingTime}
            onChange={(e) => handleNewClubInputChange(e, "meetingTime")}
          />

          <Button style={{ width: "100%" }} onClick={handleCreateClub}>
            Create Club
          </Button>
        </Modal.Body>
      </Modal>
    </>
  ) : (
    <>
      <div
        style={{
          marginBottom: "29px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              paddingRight: 10,
              paddingLeft: 10,
              marginRight: 10,
              cursor: "pointer",
            }}
            title="Go back"
            onClick={() => setSelectedClub(undefined)}
          >
            <h2 style={{ marginBottom: 0 }}>{"<"}</h2>
          </div>

          <h3 style={{ marginBottom: 0 }}>{selectedClub.name} Club Members</h3>
        </div>

        <Button
          style={{ width: "120px", height: "35px", marginTop: 0 }}
          onClick={handleOpenAddMemberModal}
        >
          Add Member
        </Button>
      </div>

      <Table columnWidths={columnWidths} headers={headers} rows={rows} />

      <Modal
        show={addMemberModalIsOpen}
        onHide={handleCloseAddMemberModal}
        dialogClassName={styles.modal}
      >
        <Modal.Header closeButton />

        <Modal.Body>
          <h4>Add Member</h4>

          <select
            style={{ width: "100%", height: "50px", margin: "8px 0 16px" }}
            value={newMember.cognito_username}
            onChange={(e) => handleNewMemberInputChange(e, "cognito_username")}
          >
            <option value="" label="Select a user..." />
            {users
              .sort((a, b) => {
                if (!a.name && !b.name) {
                  if (!a.email) {
                    return 1;
                  }
                  if (!b.email) {
                    return -1;
                  }

                  return a.email.localeCompare(b.email);
                }
                if (!a.name) {
                  return 1;
                }
                if (!b.name) {
                  return -1;
                }

                return a.name.localeCompare(b.name);
              })
              .map((user) => (
                <option
                  key={user.cognito_username}
                  value={user.cognito_username}
                  label={user.name || `No Name (${user.email || "No Email"})`}
                />
              ))}
          </select>

          <Button style={{ width: "100%" }} onClick={handleAddMember}>
            Add Member to {selectedClub.name} Club
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};
